import React from "react";
// import { StaticQuery, Link, graphql } from "gatsby";
import Header from "../components/Header/Header.js";
// import Footer from "../components/Footer";
import PropTypes from "prop-types";

class Layout extends React.Component {
	render() {
		const {
			location,
			coloring,
			headerPosition,
			headerColor,
			children
		} = this.props;
		return (
			<div className={coloring + " w-full"}>
				<Header headerColor={headerColor} headerPosition={headerPosition} />
				{children}
			</div>
		);
	}
}

Layout.propTypes = {
	coloring: PropTypes.string
};

export default Layout;
