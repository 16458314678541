import React from "react";
// import { graphql, Link } from "gatsby";
import Layout from "../components/layout";

// console.log(hero);

export default ({ data }) => (
	<Layout>
		<img src="https://placehold.it/1400x600" alt="" />
		<div className="container">
			<h1>Contact</h1>

			<div className="flex flex-wrap -mx-4">
				<div className="w-full md:w-1/3 mb-8 px-4">
					<h4>Our Office</h4>
				</div>
				<div className="w-full md:w-2/3 px-4 mb-8">
					<form
						className="w-full max-w-md"
						name="contact"
						method="POST"
						data-netlify="true"
					>
						<div className="flex flex-wrap -mx-3 mb-6">
							<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
								<label
									className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
									for="grid-first-name"
								>
									First Name
								</label>
								<input
									className="appearance-none block w-full bg-grey-lightest text-grey-darker border border-grey-lighter rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-grey"
									id="grid-first-name"
									type="text"
									placeholder="Jane"
								/>
							</div>
							<div className="w-full md:w-1/2 px-3">
								<label
									className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
									for="grid-last-name"
								>
									Last Name
								</label>
								<input
									className="appearance-none block w-full bg-grey-lightest text-grey-darker border border-grey-lighter rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-grey"
									id="grid-last-name"
									type="text"
									placeholder="Doe"
								/>
							</div>
						</div>
						<div className="flex flex-wrap -mx-3 mb-6">
							<div className="w-full px-3">
								<label
									className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
									for="grid-password"
								>
									Password
								</label>
								<input
									className="appearance-none block w-full bg-grey-lightest text-grey-darker border border-grey-lighter rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-grey"
									id="grid-password"
									type="password"
									placeholder="******************"
								/>
								<p className="text-grey-dark text-xs italic">
									Make it as long and as crazy as you'd like
								</p>
							</div>
						</div>
						<div className="flex flex-wrap -mx-3 mb-2">
							<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
								<label
									className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
									for="grid-city"
								>
									City
								</label>
								<input
									className="appearance-none block w-full bg-grey-lightest text-grey-darker border border-grey-lighter rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-grey"
									id="grid-city"
									type="text"
									placeholder="Albuquerque"
								/>
							</div>
							<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
								<label
									className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
									for="grid-state"
								>
									State
								</label>
								<div className="relative">
									<select
										className="block appearance-none w-full bg-grey-lightest border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-grey"
										id="grid-state"
									>
										<option>New Mexico</option>
										<option>Missouri</option>
										<option>Texas</option>
									</select>
									<div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
										<svg
											className="fill-current h-4 w-4"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 20 20"
										>
											<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
										</svg>
									</div>
								</div>
							</div>
							<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
								<label
									className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
									for="grid-zip"
								>
									Zip
								</label>
								<input
									className="appearance-none block w-full bg-grey-lightest text-grey-darker border border-grey-lighter rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-grey"
									id="grid-zip"
									type="text"
									placeholder="90210"
								/>
							</div>
						</div>
						<div className="md:flex md:items-center text-center my-8">
							<button
								className="shadow bg-purple hover:bg-purple-light focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded ml-auto"
								type="submit"
							>
								Sign Up
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</Layout>
);
