import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Button from '@material-ui/core/Button'
// import List from "@material-ui/core/List";
// import Divider from "@material-ui/core/Divider";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
// import ListItemText from "@material-ui/core/ListItemText";
// import InboxIcon from "@material-ui/icons/MoveToInbox";
// import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from '@material-ui/icons/Menu'

const styles = {
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
}

class TempNavDrawer extends React.Component {
  state = {
    top: false,
    left: false,
    bottom: false,
    right: false,
  }

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    })
  }

  render() {
    const { classes, linkslist } = this.props

    return (
      <div>
        <Button onClick={this.toggleDrawer('left', true)}>
          <MenuIcon color="secondary" />
        </Button>

        <Drawer
          open={this.state.left}
          onClose={this.toggleDrawer('left', false)}
          classes={{
            paperAnchorLeft: 'w-90vw md:w-75vw lg:w-40vw',
          }}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer('left', false)}
            onKeyDown={this.toggleDrawer('left', false)}
          >
            <div>{linkslist}</div>
          </div>
        </Drawer>
      </div>
    )
  }
}

TempNavDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(TempNavDrawer)

// {[
//   "Places",
//   "Offices",
//   "Artists",
//   "Exhibits",
//   "News",
//   "Buildings",
//   "Contact"
// ].map((text, index) => (
//   <ListItem button key={text}>
//     <ListItemIcon>
//       <IoIosColorPalette />
//     </ListItemIcon>
//     <ListItemText primary={text} />
//   </ListItem>
// ))}

// <ListLink to="/exhibits">Exhibits</ListLink>
// <ListLink to="/blogging">News</ListLink>
// <ListLink to="/buildings">Buildings</ListLink>
// <ListLink to="/contact">Contact</ListLink>
