import React from 'react'
import { Link } from 'gatsby'
import Headroom from 'react-headroom'
// import MenuListTemp from "../MenuListTemp";
// import NavDrawer from "../Drawer/NavDrawer";
import NavDrawer from '../Drawer/NavDrawerToo'

import logo from '../../images/logo-k.png'
import logoKo from '../../images/logo-ko.png'
// import FullScreen from "../../components/FullScreen";

const ListLink = props => (
  <Link
    className="block mt-4 lg:inline-block lg:mt-0 uppercase text-xs font-semibold tracking-wide trans mr-6 text-white hover:text-grey-light py-6"
    activeClassName="text-red"
    to={props.to}
  >
    {props.children}
  </Link>
)

const DrawerLink = props => (
  <Link
    className="block uppercase text-3xl font-graphik font-black trans text-black hover:text-grey-light py-4"
    activeClassName="text-cb-red"
    to={props.to}
  >
    {props.children}
  </Link>
)

const Header = ({ siteTitle }) => (
  <Headroom>
    <header>
      <nav className="flex items-center justify-between flex-wrap py-3 px-6 h-16 md:h-24 font-graphik bg-black">
        <div className="flex items-center flex-grow md:flex-no-shrink text-white md:mr-6">
          <Link
            to="/"
            className="text-white hover:text-grey uppercase w-16 md:w-24 mx-auto md:mx-0 block text-center"
          >
            <img src={logoKo} className="h-12 w-12" alt="Logo" />
          </Link>
        </div>

        <div className="w-full hidden block text-right flex-grow lg:flex lg:items-center lg:w-auto">
          <div className="text-sm flex items-center ml-auto">
            <ListLink to="/work">Work</ListLink>
            <ListLink to="/about">About</ListLink>
            <ListLink to="/careers">Careers</ListLink>
            <ListLink to="/pancakes">Pancakes</ListLink>
            <ListLink to="/contact">Contact</ListLink>
          </div>
        </div>
        <div className="lg:hidden absolute pin-r">
          <NavDrawer
            linkslist={
              <div className="w-3/4 flex flex-col items-center mx-auto h-screen justify-center">
                <DrawerLink to="/work">Work</DrawerLink>
                <DrawerLink to="/about">About</DrawerLink>
                <DrawerLink to="/careers">Careers</DrawerLink>
                <DrawerLink to="/pancakes">Pancakes</DrawerLink>
                <DrawerLink to="/contact">Contact</DrawerLink>
              </div>
            }
          />
        </div>
      </nav>
    </header>
  </Headroom>
)

export default Header
// <FullScreen />

// <MenuListTemp />
// <NavDrawer />
